import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['links', 'template', 'answerContainer', 'radio', 'destroy'];

  connect() {
    this.toggleAnswers();
  }

  category() {
    return this.radioTargets.filter((el) => el.checked)[0].value;
  }

  toggleAnswers() {
    if (this.category() === 'free_text') {
        this.answerContainerTarget.style.visibility = 'hidden';
        this.destroyTargets.forEach((el) => el.value = 1);
    } else {
        this.answerContainerTarget.style.visibility = 'visible';
        this.destroyTargets.forEach((el) => (el.value = 0));
    }
  }

  add(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );

    this.linksTarget.insertAdjacentHTML('beforebegin', content);
  }

  remove(event) {
    event.preventDefault();
    let wrapper = event.target.closest('.nested-fields');
    let nue = wrapper.dataset.newRecord === 'true';
    if (nue) {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
    }
  }
}
