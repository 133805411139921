import * as ActiveStorage from "@rails/activestorage";
import "bootstrap-icons/font/bootstrap-icons.css";
import * as Turbo from "@hotwired/turbo";
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import "bootstrap";
import "channels";
import "animate.css";
import "chartkick/chart.js";
import "./application.scss";
import "stimulus";

Rails.start();
Turbo.start();
ActiveStorage.start();

document.addEventListener("DOMContentLoaded", function (event) {
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
});
