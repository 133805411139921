import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab'];

  connect() {
    const notice = document.querySelector('#flash-container');
    if (notice) {
      this.tabTarget.classList.add("show");
    }
  }
}
