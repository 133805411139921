import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['notice'];

  connect() {
    const notice = document.querySelector('#flash-container');
    if (notice) {
       setTimeout(() => {
         this.noticeTarget.classList.add('animate__fadeOut');
         notice.remove();
       }, 3000);
    }
  }
}
