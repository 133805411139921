import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  trigger() {
    // wait for the animation to finish then resize window
    setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
    }, 500);
  }
}
