import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['disable', 'input', 'download'];

  connect() {
    if (this.inputTarget) {
      this.toggleDisable();
    }
  }

  toggleDisable() {
    if (this.inputTarget.value !== undefined && this.inputTarget.value !== '') {
      this.disableTarget.disabled = false;
    } else {
      this.disableTarget.disabled = true;
    }
  }

  download() {
    this.downloadTarget.disabled = true;
    this.downloadTarget.textContent = 'Downloading...';
    // set timeout for 10 seconds
    setTimeout(() => {
      this.downloadTarget.disabled = false;
      this.downloadTarget.textContent = 'Download All Leads';
    }, 10000);
  }
}
