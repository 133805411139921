import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", "input"];
  connect() {
    console.log("here");
  }

  attachFile() {
    this.submitTarget.disabled = false;
  }
}
